import { render, staticRenderFns } from "./comp_CategoriesMegaMenuHorizontal.vue?vue&type=template&id=7d549381&scoped=true&"
import script from "./comp_CategoriesMegaMenuHorizontal.vue?vue&type=script&lang=js&"
export * from "./comp_CategoriesMegaMenuHorizontal.vue?vue&type=script&lang=js&"
import style0 from "./comp_CategoriesMegaMenuHorizontal.vue?vue&type=style&index=0&id=7d549381&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7d549381",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCol,VListItemTitle,VRow})
