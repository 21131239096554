<script>
import productCardMixin from '@/v-shop/mixins/product-card-mixin'
export default {
	mixins: [productCardMixin],
	computed: {
		isAlgolia() {
			return this.$srv('isAlgolia')
		},
	},
}
</script>

<template>
	<v-list-item
		:to="productRoute"
		@click="cardClick()"
		class="quick-search__item"
		style="color: #444 !important"
		link
		active-class="v-list-item"
	>
		<v-list-item-avatar>
			<Media :src="product.images[0].thumb" width="100%" aspect-ratio="1" />
		</v-list-item-avatar>
		<v-list-item-content class="link--text font-3">
			<div
				v-html="product._highlightResult.name.value"
				class="header font-1"
				v-if="isAlgolia && product._highlightResult"
			/>
			<div class="header font-1" v-else>{{ product.name }}</div>
			<PriceLayout
				class="d-flex flex-row-reverse justify-end mt-1"
				:prevPrice="selectedVariant.pvPrice.prevPrice"
				:price="selectedVariant.pvPrice.price"
				prev-price-class="font-2 grey--text"
				price-class="font-2 mr-2 price"
				discount-text="-"
			/>
		</v-list-item-content>
	</v-list-item>
</template>
<style>
.quick-search__item em {
	background: #ffff004a;
	font-style: normal;
}
</style>

